/********************************/
/*********** TEAM UP ************/
/********************************/

/********* SOMMAIRE *************/
/*
  1 - Général
  2 - Modals
  3 - Formulaires
  4 - Buttons
  5 - Menus
  6 - Pages
    6.1 - Inscription + Connexion
    6.2 - Ma dotation
    6.3 - Mon classement
    6.4 - Ma position
    6.5 - Mon équipe
    6.6 - Choisir avatar
    6.7 - Modifier mot de passe
    6.8 - FAQ
    6.9 - Choix du canal

/*---------- 1 - Général ----------*/

.w100 {
    width: 100%;
}

.displayIB {
    display: inline-block;
}

/*---------- 2 - Modals ----------*/

.modal button.closeModal {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 30px;
    right: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px;
    background-image: url(/dist/files/closeModal.6024e66e5d8e32526e4157c559259e72.svg);
    opacity: 1;
}

/* Modal simple */

html .modalSimple.modal.show .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.modalSimple .modal-content .modal-body {
    padding: 10px 24px 10px;
}

.modalSimple .modal-footer button {
    font-weight: 400;
}

.modalSimple .modal-footer button.btn.btn-primary {
    color: #ff7900;
}

.modalSimple .blocDestinataire {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.modalSimple .blocDestinataire img {
    width: 44px;
}

/* Modal with icon */

html .modalIcon.modal.show .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.modalIcon .modal-body {
    padding: 30px;
}

.modalIcon .modal-body img.coucou {
    width: 120px;
    margin: 0 auto;
}

.modalIcon .modal-body img.desole,
.modalIcon .modal-body img.bravo {
    width: 180px;
    margin: 0 auto;
}

.modalIcon .modal-body p.titre {
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 30px;
}

.modalIcon .modal-body p.sousTitre {
    font-size: 18px;
    color: #595959;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 15px;
    max-width: 200px;
    margin: 15px auto 0;
}

.modalIcon .modal-content {
    width: 85%;
    margin: 0 auto;
}

/*---------- 3 - Formulaires ----------*/

div.is-focused .form-control {
    background-image: -webkit-linear-gradient(bottom, #ff7900 2px, rgba(0, 150, 136, 0) 0%),
        -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.26) 1px, transparent 0%);
}

input.form-control {
    background-image: -webkit-linear-gradient(bottom, #8f8f8f 2px, rgba(0, 150, 136, 0) 0%),
        -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 1px, transparent 0%);
}

div.is-focused label.bmd-label-floating {
    color: #ff7900;
}

div.form-group label {
    color: #8f8f8f;
}

div.checkbox {
    color: #595959;
}

div.checkbox .checkbox-decorator {
    top: 2px;
}

div.checkbox .checkboxLabel {
    margin-left: 3px;
}

.checkbox label input[type="checkbox"]:checked + .checkbox-decorator .check {
    color: #ff7900;
    border-color: #ff7900;
}

.checkbox label input[type="checkbox"]:checked + .checkbox-decorator .check::before {
    color: #ff7900;
}

a.infoBulle {
    position: absolute;
    right: 0;
    bottom: 5px;
    background-color: #8f8f8f;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    font-size: 13px;
}

a.infoBulle:hover {
    color: #fff;
}

/*---------- 4 - Buttons ----------*/

/** cta submit **/

.ctaSubmit {
    display: block;
    margin: 20px auto 0;
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 30px;
    background-color: #ff7900;
    font-size: 18px;
    color: #fff;
    border: 0;
}

.ctaSubmit:hover,
.ctaAction:focus {
    color: #fff;
}

.ctaSubmit.mt30 {
    margin: 30px auto 0;
}

.ctaSubmit.mt40 {
    margin: 40px auto 0;
}

/** cta action **/

.ctaAction {
    display: block;
    margin: 20px auto 0;
    width: 100%;
    max-width: 170px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 30px;
    background-color: #ff7900;
    font-size: 18px;
    color: #fff;
    border: 0;
}

.ctaAction:hover,
.ctaAction:focus {
    color: #fff;
}

/*--------- 5 - Menus ---------*/

/* menu principal */

header div#menu-principal {
    position: fixed;
    left: 0;
    top: 55px;
    width: 100%;
    height: 48px;
    background-color: #000;
    z-index: 100;
    transition-duration: 300ms;
}

header div#menu-principal.hidden {
    height: 0;
}

header div#menu-principal ul {
    width: 100%;
    height: 100%;
    display: flex;
}

header div#menu-principal ul li {
    width: calc(100% / 4);
    height: 100%;
    text-align: center;
    line-height: 48px;
    border-bottom: 2px solid transparent;
}

header div#menu-principal ul li.active {
    border-bottom: 2px solid #ff7900;
}

header div#menu-principal ul li span {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
}

header div#menu-principal ul li span#dotation {
    background-image: url(/dist/files/dotation.fe53608436046a79e3753abf51b82073.svg);
}

header div#menu-principal ul li span#classement {
    background-image: url(/dist/files/classement.d2b5a67ba2e85936f67485700c1d3eb4.svg);
}

header div#menu-principal ul li span#position {
    background-image: url(/dist/files/position.686f18c9bcbb28914a8c6c00015223e9.svg);
}

header div#menu-principal ul li span#equipe {
    background-image: url(/dist/files/equipe.bfebb14de1e8646dc90d30164e136fbd.svg);
}

div.containerCibleMenuPrincipal {
    display: none;
}

main.filter-dotation div#dotationContainer.containerCibleMenuPrincipal {
    display: block;
}

main.filter-classement div#classementContainer.containerCibleMenuPrincipal {
    display: block;
}

main.filter-position div#positionContainer.containerCibleMenuPrincipal {
    display: block;
}

main.filter-equipe div#equipeContainer.containerCibleMenuPrincipal {
    display: block;
}

/*---------- 6 - Pages ----------*/

/*---------- 6.1 - Inscription + Connexion ----------*/

.logo-teamup {
    width: 80px;
    margin: 0 auto;
    padding-top: 40px;
}

div.form_container {
    padding: 20px 35px 0;
}

div.form_container a#cgu:hover {
    color: #ff7900;
}

.logo-lettre-hello {
    width: 140px;
    margin: 0 auto;
    padding-top: 40px;
}

.mdpOublie {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 15px;
}

/*---------- 6.2 - Ma dotation ----------*/

div.containerGains .cardGain {
    background: white;
    height: 72px;
    margin-left: 55px;
    margin-bottom: 20px;
    border-radius: 8px;

    -webkit-appearance: none;
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);

    display: flex;
    position: relative;
    align-items: center;

    display: none;
}

main.filter-dotation div.containerGains .cardGain {
    display: flex;
}

div.containerGains .cardGain.active {
    background-color: #ff7900;
}

div.containerGains .cardGain .pictoGain {
    width: 40px;
    margin-left: 20px;
}

div.containerGains .cardGain .txtGain {
    text-transform: uppercase;
    font-size: 15px;
    margin-left: 22px;
}

div.containerGains .cardGain.old .pointerGain {
    position: absolute;
    left: -46px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid #ff7900;
}

div.containerGains .cardGain.old .pointerGain::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 10.5px;
    height: 10.5px;
    border-radius: 50%;
    background-color: #ff7900;
}

div.containerGains .cardGain.old.first .cheminGain {
    display: none;
}

div.containerGains .cardGain.old .cheminGain,
div.containerGains .cardGain.active .cheminGain {
    position: absolute;
    left: -37px;
    top: -41px;
    width: 3px;
    height: 62px;
    background-color: #ff7900;
}

div.containerGains .cardGain.active .pointerGain {
    position: absolute;
    left: -46px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: #ff7900;
}

div.containerGains .cardGain.future .pointerGain {
    position: absolute;
    left: -46px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 2px solid #d6d6d6;
}

div.containerGains .cardGain.future .cheminGain {
    position: absolute;
    left: -37px;
    top: -41px;
    width: 3px;
    height: 62px;
    background-color: #d6d6d6;
}

/*---------- 6.3 - Mon classement ----------*/

#classementContainer .blocTopOrange {
    position: relative;
    color: white;
    height: 60px;
    line-height: 60px;
}
#classementContainer .blocTopOrange::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 220px;
    background: #ff7900;
}

/* Circle progress bar */

.circle {
    width: 200px;
    height: 200px;
    display: block;
    position: relative;
    margin: 30px auto;
}

.circle strong {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 45px;
    font-size: 43px;
}

.circle .circle-unity {
    position: absolute;
    top: 105px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 45px;
    font-size: 18px;
    text-transform: uppercase;
    color: #8f8f8f;
}

/* Progress bar */

.barWrapper {
    position: relative;
    margin: 20px 10px;
}

.tooltip {
    position: relative;
    float: right;
}
.tooltip > .tooltip-inner {
    background-color: #eebf3f;
    padding: 5px 15px;
    color: rgb(23, 44, 66);
    font-weight: bold;
    font-size: 13px;
}
.popOver + .tooltip > .tooltip-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #eebf3f;
}

.progress {
    border-radius: 0;
    overflow: visible;
    border-radius: 20px !important;
    height: 14px;
}
.progress-bar {
    background-color: #50be87;
    -webkit-transition: width 1.5s ease-in-out;
    transition: width 1.5s ease-in-out;
    border-radius: 20px !important;
    width: 0%;
    position: relative;
}
.progressText {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

span.arrivee {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/dist/files/flag.553e08e1a9ea503cd5dc309b505a51d5.svg);
}

span.tooltipCircle {
    position: absolute;
    left: 0px;
    top: -6px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: #50be87;
    border-radius: 50%;

    -webkit-appearance: none;
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

/*****/

div.blocPrecisionClassement {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

div.blocPrecisionClassement .card {
    padding: 10px;
    flex-grow: 1;
    flex-basis: 0;
}

div.blocPrecisionClassement p.titlePrecision {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 800;
}

div.blocPrecisionClassement div.blocRang {
    margin-right: 10px;
}

/* div.blocPrecisionClassement div.blocProgression{
  margin-left: 20px;
} */

div.blocPrecisionClassement div.blocRang p.rang {
    font-size: 14px;
    color: #8f8f8f;
}

div.blocPrecisionClassement div.blocRang p.rang span {
    font-weight: bold;
}

div.blocPrecisionClassement div.blocProgression .progression {
    display: inline-block;
    font-size: 16px;
    color: #8f8f8f;
    vertical-align: middle;
}

div.blocPrecisionClassement div.blocProgression .progression::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-size: 14px;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 4px;
    margin-right: 3px;
}

div.blocPrecisionClassement div.blocProgression .progression.up::before {
    background-image: url(/dist/files/upGreen.e336735712d65b854f47bd54fb94b7c4.svg);
}

div.blocPrecisionClassement div.blocProgression .progression.egal::before {
    background-image: url(/dist/files/egalGray.f02e228acfaf9c84fabe7d0bcd00709e.svg);
}

div.blocPrecisionClassement div.blocProgression .progression.down::before {
    background-image: url(/dist/files/downRed.238d5491bfaa6da04b2e308ce276a69a.svg);
}

/*****/

div.blocPrecisionFixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color: #ff7900;
}

div.blocPrecisionFixed span {
    display: inline-block;
}

div.blocPrecisionFixed span.title {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    display: block;
}

div.blocPrecisionFixed span.pictoProgression::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-size: 14px;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 4px;
    margin-right: 3px;
}

div.blocPrecisionFixed span.pictoProgression.up::before {
    background-image: url(/dist/files/up-blanc.b4d0008ca8c265ae01dcf6b97e3525d3.svg);
}

div.blocPrecisionFixed span.pictoProgression.egal::before {
    background-image: url(/dist/files/egal-blanc.ca6c23b2a297ba59551257964bf52703.svg);
}

div.blocPrecisionFixed span.pictoProgression.down::before {
    background-image: url(/dist/files/down-blanc.d28dba89cea135ea31113f3ffdbf1299.svg);
}

/*---------- 6.4 - Ma position ----------*/

div.blocTop3 {
    background-color: #492191;
    padding: 20px 30px;
}

div.blocTop3 .scoresContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
}
div.blocTop3 .scoresContainer > * {
    flex-basis: 0;
    flex-grow: 1;
}

div.blocTop3 div.scoresContainer p.numeroRang {
    font-size: 12px;
    margin-bottom: 8px;
    color: #fff;
    text-transform: uppercase;
}

div.blocTop3 div.scoresContainer p.nameRang {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 3px;
    color: #fff;
    font-weight: 700;
}

div.blocTop3 div.scoresContainer p.nbPoints {
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
}

div.blocTop3 div.scoresContainer .rang1 img {
    width: 60px;
    margin: 0 auto;
    z-index: 10;
    position: relative;
}

div.blocTop3 div.scoresContainer .rang2 img,
div.blocTop3 div.scoresContainer .rang3 img {
    width: 44px;
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 10;
    position: relative;
}

div.maPositionContainer div.maPositionElement {
    display: none;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 60px;
    padding-left: 10px;
    padding-right: 14px;
    border-radius: 5px;
    position: relative;
    background: white;

    -webkit-appearance: none;
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.badgeClassement {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    padding: 1px 0px;
    width: 30px;
    border-radius: 10px;
    text-align: center;
}

.badgeClassement.or {
    background-color: #ffb400;
}

.badgeClassement.argent {
    background-color: #8f8f8f;
}

.badgeClassement.bronze {
    background-color: #62342d;
}

.socle-blanc-44 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translateY(-50%, -50%);
    background-color: #fff;
    border-radius: 50%;
    width: 46px;
    height: 46px;
    z-index: 1;
}

.socle-blanc-60 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translateY(-50%, -50%);
    background-color: #fff;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    z-index: 1;
}

div.maPositionContainer div.maPositionElement.active {
    background-color: #ff7900;
}

div.maPositionContainer div.maPositionElement img {
    width: 44px;
    margin: 0 12px;
    position: relative;
    z-index: 10;
}

div.maPositionContainer div.maPositionElement p.nbPoints {
    color: #8f8f8f;
    text-align: right;
    flex: 1;
}

div.maPositionContainer div.maPositionElement.active p.nbPoints,
div.maPositionContainer div.maPositionElement.active p.name {
    color: #fff;
}

main.filter-position div#positionContainer.containerCibleMenuPrincipal div.rang1,
main.filter-position div#positionContainer.containerCibleMenuPrincipal div.rang2,
main.filter-position div#positionContainer.containerCibleMenuPrincipal div.rang3 {
    display: block;
}

main.filter-position div#positionContainer.containerCibleMenuPrincipal div.maPositionElement {
    display: flex;
}

/*---------- 6.5 - Mon équipe ----------*/

main.filter-equipe div#equipeContainer.containerCibleMenuPrincipal div.monEquipeContainer .oneEquipeLine {
    display: flex;
}

div.monEquipeContainer .oneEquipeLine {
    width: 100%;
    height: 98px;
    display: none;
    border-bottom: 1px solid #eeeeee;
    padding: 15px 0 15px 23px;
    position: relative;
}

div.monEquipeContainer .oneEquipeLine img {
    width: 44px;
    margin-bottom: 4px;
}

div.monEquipeContainer .oneEquipeLine div.containerMiddle {
    margin-left: 20px;
}

div.monEquipeContainer .oneEquipeLine p.typePoste {
    font-size: 12px;
    color: #8f8f8f;
    margin: 6px 0;
}

div.monEquipeContainer .oneEquipeLine p.progression {
    display: inline-block;
    font-size: 16px;
    color: #8f8f8f;
    vertical-align: middle;
}

div.monEquipeContainer .oneEquipeLine p.progression::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-size: 14px;
    width: 14px;
    height: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 4px;
    margin-right: 3px;
}

div.monEquipeContainer .oneEquipeLine p.progression.up::before {
    background-image: url(/dist/files/upGreen.e336735712d65b854f47bd54fb94b7c4.svg);
}

div.monEquipeContainer .oneEquipeLine p.progression.egal::before {
    background-image: url(/dist/files/egalGray.f02e228acfaf9c84fabe7d0bcd00709e.svg);
}

div.monEquipeContainer .oneEquipeLine p.progression.down::before {
    background-image: url(/dist/files/downRed.238d5491bfaa6da04b2e308ce276a69a.svg);
}

div.monEquipeContainer .oneEquipeLine p.nbPoints {
    text-transform: uppercase;
    font-size: 15px;
}

div.monEquipeContainer .oneEquipeLine div.footerElement {
    display: flex;
    justify-content: space-between;
    width: 152px;
}

div.monEquipeContainer .oneEquipeLine a.coucou {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translateY(-50%, -50%);
    background-size: 44px;
    width: 44px;
    height: 44px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(/dist/files/clap.257f74a70104bb45ecde1cbaa0a3df4e.svg);
}

/*---------- 6.6 - Choisir avatar ----------*/

main div.container_selectionnerAvatar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px;
}

main div.container_selectionnerAvatar .oneAvatarElement {
    width: calc(100% / 3 - 3px);
    /* height ajoutée en js */
    height: 0px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 5px;
}

/*---------- 6.7 - Modifier mot de passe ----------*/

form div.form-group span.oeil {
    position: absolute;
    right: 0;
    bottom: 5px;
    width: 22px;
    height: 22px;
    background-size: 22px;
    background-position: center center;
    background-repeat: no-repeat;
}

form div.form-group span.oeil.hide {
    background-image: url(/dist/files/oeil.e7f1b5c1305f30dfa8b7b4521cdf3d3b.svg);
}

form div.form-group span.oeil.show {
    background-image: url(/dist/files/oeil-hide.324de3e0ad2d0c2914fa211aecf8a507.svg);
}

/*---------- 6.8 - FAQ ----------*/

div.container-faq {
    margin: 0 10px;
}

div.container-faq p.categorie-faq {
    font-size: 14px;
    margin: 20px 0;
    font-weight: 700;
}

div.container-faq p.categorie-faq.niveau-0 {
    color: #ff7900;
}

div.container-faq p.categorie-faq.niveau-1 {
    color: #085ebd;
    margin-left: 20px;
}

div.container-faq div.containerElement {
    margin-top: 15px;
}

div.container-faq div.containerElement .oneElement {
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 3px 10px -7px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

div.container-faq div.containerElement .fixedLine {
    position: relative;
    width: 100%;
    padding: 7px 11px;
    display: flex;
    align-items: center;
}

div.container-faq div.containerElement .fixedLine p {
    flex: 1;
    margin-right: 10px;
}

div.containerElement .oneElement i {
    color: #8f8f8f;
}

div.containerElement .oneElement.focus i {
    transform: rotate(90deg);
}

div.containerElement .oneElement .contenu {
    display: none;
    padding: 20px;
    font-size: 14px;
    color: #8f8f8f;
}

div.containerElement .oneElement.focus .contenu {
    display: block;
}

div.containerElement .oneElement.focus {
    margin-bottom: 20px;
}

/*---------- 6.9 - Choix du canal ----------*/

.iconTelevision {
    margin: 0 auto;
    width: 140px;
    padding-top: 50px;
}

.indicationCanal {
    font-size: 18px;
    color: #8f8f8f;
    width: 100%;
    display: inline-block;
    text-align: center;
    /* margin-top: 40px; */
    /* margin-bottom: 25px; */
}

.ctaCanal {
    /*display: block;
  width:250px;
  height:50px;
  text-align: center;
  line-height:50px;*/
    background-color: #4bb4e6;
    /*border-radius: 5px;
  font-size: 18px;
  color:#FFF;
  margin: 0 auto 25px;*/
}

.ctaCanal:focus,
.ctaCanal:hover {
    color: #fff;
}
