@charset "UTF-8";
/********************************/
/******** MOBICHALLENGE *********/
/********************************/
/********* SOMMAIRE *************/
/*
  0 - Modules
  1 - Général
  2 - Utile
  3 - Fonts
  4 - Colors
  5 - Margins et paddings
  6 - Buttons
  7 - Avatars
  8 - Badges
  9 - Header
  10 - Menu
  11 - Main
  12 - Pages
    12.0 - Page de connexion
    12.1 - Launcher
    12.2 - Challenges
    12.3 - Notifications
    12.4 - Mon journal
    12.5 - Likes
    12.6 - Creer un post
    12.7 - Commentaires
    12.8 - Mon profil
    12.9 - Selectionner photos
*/
/*---------- 0 - Modules ----------*/
.image-gallery-icon {
  color: #fff;
  transition: all .3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 28px;
    width: 28px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 2px 2px #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    transition: all .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus {
      transform: scale(1.2);
      background: #337ab7;
      border: 1px solid #337ab7; }
    .image-gallery-bullets .image-gallery-bullet.active {
      transform: scale(1.2);
      border: 1px solid #fff;
      background: #fff; }
    @media (hover: hover) and (pointer: fine) {
      .image-gallery-bullets .image-gallery-bullet:hover {
        background: #337ab7;
        border: 1px solid #337ab7; }
      .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #337ab7; } }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .3s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    display: block;
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery-thumbnail:hover {
      outline: none;
      border: 4px solid #337ab7; } }
  @media (hover: hover) and (pointer: fine) and (max-width: 768px) {
    .image-gallery-thumbnail:hover {
      border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

/*---------- 1 - Général ----------*/
* {
  box-sizing: border-box; }

body {
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  background-color: #fff; }

body.grey {
  background: #eeeeee; }

a,
a:hover,
a:focus {
  text-decoration: none;
  color: #ff7400; }

p,
ul {
  margin: 0; }

ul li {
  list-style: none; }

a.removeStyle {
  color: black !important; }

.lien {
  color: #ff7900; }

.relative {
  position: relative; }

.block {
  display: block; }

.container {
  padding: 10px 10px 0; }

h2 {
  color: #ff7900;
  font-size: 18px;
  font-weight: 700; }

form {
  margin-bottom: 0; }

.alert {
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0 3px 10px -7px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.alert.alert-warning,
.alert.alert-danger {
  background: #ffb400;
  color: white; }

.alert-success {
  background: #50be87;
  color: white;
  font-weight: 600; }

.flex-center,
.align-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

/* Input material outlined */
.outline-input,
.outline-input * {
  all: unset; }

.outline-input {
  width: 250px;
  margin: 0 0 10px 0;
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden; }

/* Input, Textarea */
.outline-input input,
.outline-input textarea {
  text-align: left;
  box-sizing: border-box;
  margin: 0;
  border: solid 1px;
  /* Safari */
  border-color: #d6d6d6;
  border-top-color: transparent;
  border-radius: 4px;
  padding: 15px 13px 15px;
  width: 100%;
  height: inherit;
  color: black;
  background-color: transparent;
  box-shadow: none;
  /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: #ff7400;
  transition: border 0.2s, box-shadow 0.2s; }

.outline-input.small input {
  height: 50px; }

.outline-input input:-webkit-autofill,
.outline-input input:-webkit-autofill:hover,
.outline-input input:-webkit-autofill:focus,
.outline-input input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

/* Span */
.outline-input input + span,
.outline-input textarea + span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-color: #d6d6d6;
  width: 100%;
  max-height: 100%;
  color: #8f8f8f;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s; }

/* Corners */
.outline-input input + span::before,
.outline-input input + span::after,
.outline-input textarea + span::before,
.outline-input textarea + span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px;
  border-top-color: #d6d6d6;
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s; }

.outline-input input + span::before,
.outline-input textarea + span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0; }

.outline-input input + span::after,
.outline-input textarea + span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px; }

/* Hover */
.outline-input:hover input,
.outline-input:hover textarea {
  border-color: #d6d6d6;
  border-top-color: transparent; }

.outline-input:hover input + span::before,
.outline-input:hover textarea + span::before,
.outline-input:hover input + span::after,
.outline-input:hover textarea + span::after {
  border-top-color: #ff7400; }

.outline-input:hover input:not(:focus):placeholder-shown,
.outline-input:hover textarea:not(:focus):placeholder-shown {
  border-color: #d6d6d6; }

/* Placeholder-shown */
.outline-input input:not(:focus):placeholder-shown,
.outline-input textarea:not(:focus):placeholder-shown {
  border-top-color: #d6d6d6; }

.outline-input input:not(:focus):placeholder-shown + span,
.outline-input textarea:not(:focus):placeholder-shown + span {
  font-size: inherit;
  line-height: 68px; }

.outline-input.small input:not(:focus):placeholder-shown + span {
  line-height: 60px; }

.outline-input input:not(:focus):placeholder-shown + span::before,
.outline-input textarea:not(:focus):placeholder-shown + span::before,
.outline-input input:not(:focus):placeholder-shown + span::after,
.outline-input textarea:not(:focus):placeholder-shown + span::after {
  border-top-color: transparent; }

/* Focus */
.outline-input input:focus,
.outline-input input:-webkit-autofill:focus,
.outline-input textarea:focus {
  border-color: #ff7400;
  border-top-color: transparent;
  box-shadow: inset 1px 0 #ff7400, inset -1px 0 #ff7400, inset 0 -1px #ff7400;
  outline: none; }

.outline-input input:focus + span,
.outline-input input:-webkit-autofill:focus + span,
.outline-input textarea:focus + span {
  color: #ff7400; }

.outline-input input:focus + span::before,
.outline-input input:focus + span::after,
.outline-input input:-webkit-autofill:focus + span::before,
.outline-input input:-webkit-autofill:focus + span::after,
.outline-input textarea:focus + span::before,
.outline-input textarea:focus + span::after {
  box-shadow: inset 0 1px #ff7400; }

/* Disabled */
.outline-input input:disabled,
.outline-input input:disabled + span,
.outline-input textarea:disabled,
.outline-input textarea:disabled + span {
  border-color: #d6d6d6 !important;
  border-top-color: transparent !important;
  color: #d6d6d6;
  pointer-events: none; }

.outline-input input:disabled + span::before,
.outline-input input:disabled + span::after,
.outline-input textarea:disabled + span::before,
.outline-input textarea:disabled + span::after {
  border-top-color: #d6d6d6 !important; }

.outline-input input:disabled:placeholder-shown,
.outline-input input:disabled:placeholder-shown + span,
.outline-input textarea:disabled:placeholder-shown,
.outline-input textarea:disabled:placeholder-shown + span {
  border-top-color: #d6d6d6 !important; }

.outline-input input:disabled:placeholder-shown + span::before,
.outline-input input:disabled:placeholder-shown + span::after,
.outline-input textarea:disabled:placeholder-shown + span::before,
.outline-input textarea:disabled:placeholder-shown + span::after {
  border-top-color: transparent !important; }

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .outline-input input,
    .outline-input input + span,
    .outline-input textarea,
    .outline-input textarea + span,
    .outline-input input + span::before,
    .outline-input input + span::after,
    .outline-input textarea + span::before,
    .outline-input textarea + span::after {
      transition-duration: 0.1s; } } }

/* Modal */
.materialModal,
.materialModal .modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%; }

.materialModal .modal-header h5 {
  font-size: 18px; }

.materialModal .modal-header img {
  width: 100px;
  margin: 5px 0 30px; }

.materialModal .modal-body {
  color: #595959;
  font-size: 16px; }

.materialModal .modal-footer {
  margin-bottom: 40px; }

.modal-content .modal-body {
  padding-bottom: 0px; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }

/*---------- 2 - Utile ----------*/
.noMargin {
  margin: 0; }

.noPadding {
  padding: 0; }

.centerDiv {
  margin: 0 auto; }

.taCenter {
  text-align: center; }

.flex {
  display: flex; }

.flex .spacer {
  flex: 1; }

select.form-control {
  height: 50px !important;
  padding: 15px 13px;
  border: 1px solid #d6d6d6;
  background-image: none !important;
  border-radius: 5px;
  -webkit-appearance: inherit; }

/*---------- 3 - Fonts ----------*/
.t10 {
  font-size: 10px; }

.t12 {
  font-size: 12px; }

.t14 {
  font-size: 14px; }

.t18 {
  font-size: 18px; }

.t20 {
  font-size: 20px; }

.bold {
  font-weight: 800; }

/*---------- 4 - Colors ----------*/
.persoBlanc {
  color: #fff; }

.persoNoir {
  color: #000; }

.persoGris {
  color: #595959; }

.persoGrisClair {
  color: #8f8f8f; }

.persoOrange {
  color: #ff7900; }

/*---------- 5 - Margins et paddings ----------*/
.mt1 {
  margin-top: 1px; }

.mt5 {
  margin-top: 5px; }

.mt8 {
  margin-top: 8px; }

.mt10 {
  margin-top: 10px; }

.mt13 {
  margin-top: 13px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mt40 {
  margin-top: 40px; }

.mt50 {
  margin-top: 50px; }

/**/
.-mb1 {
  margin-bottom: -1px; }

.-mb2 {
  margin-bottom: -2px; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb35 {
  margin-bottom: 35px; }

/**/
.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 15px; }

/***/
.p10 {
  padding: 10px; }

/*---------- 6 - Buttons ----------*/
.cta {
  display: block;
  margin: 0 auto;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 30px;
  background-color: #ff7900;
  font-size: 18px;
  color: #fff;
  border: 0;
  cursor: pointer; }

.cta:hover,
.cta:focus {
  color: #fff; }

.cta-grey {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 14px;
  margin: 0 auto;
  border: none;
  border-radius: 5px;
  background-color: #8f8f8f;
  color: white;
  font-size: 18px; }

.cta-grey i {
  margin-right: 10px; }

/*---------- 7 - Avatars ----------*/
#avatar1 {
  background-image: url(/dist/files/avatar1.1ede182ca576162e19e9c00c3121a362.svg); }

#avatar2 {
  background-image: url(/dist/files/avatar2.af39479ad9f96991bd0400254694303e.svg); }

#avatar3 {
  background-image: url(/dist/files/avatar3.ce6317b71a16b29bb119802772e372d6.svg); }

#avatar4 {
  background-image: url(/dist/files/avatar4.2cd251a77946d5b464949377f4f804e4.svg); }

#avatar5 {
  background-image: url(/dist/files/avatar5.f0bb54e6befa6f14066174f198e52ec9.svg); }

/*--------- 8 - Badges ----------*/
.badgeAstuce {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 50vh; }

.bonne_pratique {
  background-color: #ff8ad4; }

.bonne_idee {
  background-color: #ffd200; }

.information {
  background-color: #a885d8; }

.message {
  background-color: #4bb4e6; }

.sondage {
  background-color: #8f8f8f; }

.pastille {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eeeeee;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat; }

.pastille.active {
  background-color: #595959; }

.p-grisClair {
  background-color: #8f8f8f; }

.p-grisFonce {
  background-color: #595959; }

.p-orange {
  background-color: #ff7900; }

.p-bleuFonce {
  background-color: #085ebd; }

.p-bleuClair {
  background-color: #4bb4e6; }

.p-vertFonce {
  background-color: #0a6e31; }

.p-vertClair {
  background-color: #50be87; }

.p-roseFonce {
  background-color: #ff8ad4; }

.p-roseClair {
  background-color: #ffb4e6; }

.p-violetFonce {
  background-color: #492191; }

.p-violetClair {
  background-color: #a885d8; }

.p-jauneFonce {
  background-color: #ffb400; }

.p-jauneClair {
  background-color: #ffd200; }

.p-marron {
  background-color: #714e46; }

.icon-like-blanc {
  background-image: url(/dist/files/icon-like-blanc.d2958c18fb2d7e4d36b049e657edad53.svg); }

.icon-etoile-blanc {
  background-image: url(/dist/files/icon-etoile-blanc.145d4fe846953a5b5d0d0e5c4a1e8acc.svg);
  background-size: 22px; }

.icon-cloche-blanc {
  background-image: url(/dist/files/icon-cloche-blanc.814445a12a5b04dc0e36317eadce0922.svg);
  background-size: 17px; }

.icon-graduation-blanc {
  background-image: url(/dist/files/icon-graduation-blanc.9563e5d1998ff246ca572db124949aee.svg);
  background-size: 23px; }

.icon-megaphone-blanc {
  background-image: url(/dist/files/icon-megaphone-blanc.7a7eb1ffb1c3d7ed6ce38b34601d933f.svg);
  background-size: 28px; }

.icon-signet-blanc {
  background-image: url(/dist/files/icon-signet-blanc.3d2d61f1f503d3dded1e147218d6c1bc.svg); }

.icon-cle-blanc {
  background-image: url(/dist/files/icon-cle-blanc.3f2e03d47c7e3111def16385aedc1fbd.svg);
  background-size: 22px; }

/**/
.icon-user-blanc {
  background-image: url(/dist/files/icon-user-blanc.eae0c3e385ecb09ab7145c1ed9d8ac96.svg); }

.icon-liste-blanc {
  background-image: url(/dist/files/icon-liste-blanc.d99abd0d0d0d05716bd43a564218fc87.svg); }

.icon-coupe-blanc {
  background-image: url(/dist/files/icon-coupe-blanc.698c8f7ee5731b884483bfd719deb1bd.svg);
  background-size: 22px; }

.icon-ampoule-blanc {
  background-image: url(/dist/files/icon-ampoule-blanc.2f860b8221ea63813552057d092d5710.svg); }

.icon-cleMolette-blanc {
  background-image: url(/dist/files/icon-cleMolette-blanc.17ce74096339cf722550ddae42663cd0.svg);
  background-size: 24px; }

.icon-aide-blanc {
  background-image: url(/dist/files/icon-aide-blanc.286ca9a638fec78464f7ef0bd8557dd6.svg);
  background-size: 28px; }

.icon-message-blanc {
  background-image: url(/dist/files/icon-message-blanc.6042fb77143b28d0e349306c683d44dc.svg);
  background-size: 24px; }

.icon-coeur-blanc {
  background-image: url(/dist/files/icon-coeur-blanc.cdf8e6238806d7f12188443131cc8791.svg); }

/**/
.icon-chart-blanc {
  background-image: url(/dist/files/icon-chart-blanc.c6ac4b0b9c6b3c38d7a89f8dec5ec605.svg);
  background-size: 18px; }

/**/
.icon-panier-blanc {
  background-image: url(/dist/files/icon-panier-blanc.214d76d8b9f721742cd7be975b9eff17.svg);
  background-size: 22px; }

/**/
.icon-smiley-blanc {
  background-image: url(/dist/files/icon-smiley-blanc.7cebea46dba1d1ee935ab9f324a1b129.svg); }

/* .icon-like-blanc{
  background-image: url('../images/icon/parametres/icon-like-blanc.svg');
  
}

.icon-etoile-blanc{
  background-image: url('../images/icon/parametres/icon-etoile-blanc.svg');
  background-size: 22px;
}

.icon-cloche-blanc{
  background-image: url('../images/icon/parametres/icon-cloche-blanc.svg');
  background-size: 17px;
}

.icon-graduation-blanc{
  background-image: url('../images/icon/parametres/icon-graduation-blanc.svg');
  background-size: 23px;
}

.icon-megaphone-blanc{
  background-image: url('../images/icon/parametres/icon-megaphone-blanc.svg');
  background-size: 28px;
}

.icon-signet-blanc{
  background-image: url('../images/icon/parametres/icon-signet-blanc.svg');
}

.icon-cle-blanc{
  background-image: url('../images/icon/parametres/icon-cle-blanc.svg');
  background-size: 22px;
} */
/*--------- 9 - Header ----------*/
header div#navbar {
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  z-index: 100; }

header div#navbar.selectionnerPhoto {
  box-shadow: none; }

header div#navbar div.headerContainer {
  /* margin-top: 25px; */
  padding: 0 14px;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

header div#navbar.selectionnerPhoto div.headerContainer {
  background-color: #fff; }

header div#navbar.selectionnerPhoto div.headerContainer h1 {
  color: #000; }

header div#navbar h1 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  margin: 0;
  flex-grow: 1;
  text-align: center; }

header div#navbar a {
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center; }

header div#navbar.selectionnerPhoto a#textOK {
  font-size: 14px;
  color: #307ff4; }

header div#navbar a#menu-icon {
  width: 19px;
  height: 100%;
  background-size: 19px;
  background-image: url(/dist/files/menu.52aa1cdedbeacd94ae2a741d3c8a4835.svg); }

header div#navbar a#menu-icon-retour {
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-image: url(/dist/files/retour.2b398a1d44bbfe592dcff8f7ce8f4d73.svg); }

header div#navbar a#menu-icon-close {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-image: url(/dist/files/picto-close.874f05f0181e1ba5f03bad5fe66abff9.svg); }

header div#navbar a#alert-icon {
  width: 16px;
  height: 20px;
  background-size: 16px;
  background-image: url(/dist/files/alert.814445a12a5b04dc0e36317eadce0922.svg);
  position: relative; }

header div#navbar a#filtre-icon {
  width: 25px;
  height: 25px;
  background-size: 25px;
  margin-right: 14px;
  background-image: url(/dist/files/picto-filtre.bbb6df79d70556ec950c8b624c59ef63.svg);
  position: relative; }

header div#navbar a#alert-icon span {
  display: none;
  position: absolute;
  top: -2px;
  right: -4px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  background-color: #ff7900;
  font-size: 8px;
  color: #fff;
  border-radius: 50%; }

header div#navbar a#alert-icon span.has-notification {
  display: inline-block; }

/*--------- 10 - Menus ----------*/
header div#effetFlou {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 500; }

header.menu-is-visible div#effetFlou {
  display: block; }

header.menu-addPost-is-visible div#effetFlou {
  display: block;
  /* filter: blur(5px); */ }

header.menu-addPost-is-visible div#addPostMenu ul#addPost-list {
  display: flex; }

header div#menu {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100vh;
  max-height: 100%;
  background-color: #fff;
  z-index: 1000;
  overflow: hidden auto;
  flex-direction: column;
  flex-wrap: nowrap;
  transform: translateX(-250px);
  -webkit-transform: translateX(-250px);
  transition: 0.5s; }

header div#menu .menu-email {
  font-size: 13px; }

header.menu-is-visible div#menu {
  transform: translateX(0px);
  -webkit-transform: translateX(0px); }

header div#menu div#menu-head {
  width: 100%;
  height: 148px;
  background-color: #000;
  padding: 20px; }

header div#menu div#menu-head img#menu-logo {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-size: 32px; }

header div#menu div#menu-head .socle-blanc {
  width: 60px;
  height: 60px; }

header div#menu ul li {
  width: 100%;
  height: 55px;
  display: none; }

header div#menu ul li a {
  width: 100%;
  height: 100%;
  line-height: 60px;
  display: inline-block;
  color: #000; }

header div#menu ul li a:hover,
header div#menu ul li a:focus {
  color: #000; }

header.menu-is-visible div#menu ul li {
  display: block; }

header div#menu ul li a span {
  display: inline-block;
  vertical-align: middle; }

header div#menu ul li a span::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  margin-left: 15px;
  margin-right: 27px; }

header div#menu ul li a span.matIcon::before {
  content: none; }

header div#menu ul li a span.matIcon i {
  color: #8f8f8f;
  vertical-align: middle;
  margin: 0 27px 0 15px; }

header div#menu ul li a span#journal::before {
  background-image: url(/dist/files/journal.6448d1ebd8919207f60dbf08eb780d2b.svg);
  background-size: 24px; }

header div#menu ul li a span#animation::before {
  background-image: url(/dist/files/animation.9060c21b15922aad9aa3aef40993668b.svg);
  background-size: 24px; }

header div#menu ul li a span#carnet::before {
  background-image: url(/dist/files/icon_carnet_voyage.e5188851ed2eb720a0f0c6d89aa8edba.svg);
  background-size: 24px; }

header div#menu ul li a span#todolist::before {
  background-image: url(/dist/files/todolist.4f498b0a197bf179f8660905f1b49333.svg);
  background-size: 24px; }

header div#menu ul li a span#filInfo::before {
  background-image: url(/dist/files/fil_info.ef40901b3cc7852346d493431caebf14.svg);
  background-size: 24px; }

header div#menu ul li a span#profil::before {
  background-image: url(/dist/files/profil.39c8bab85975c73f4cbd613aee33475b.svg);
  background-size: 24px; }

header div#menu ul li a span#challenge::before {
  background-image: url(/dist/files/challenge.3d8b2063f0a0a0bcd2e838958ef56500.svg);
  background-size: 24px; }

header div#menu ul li a span#faq::before {
  background-image: url(/dist/files/faq.f0eb91340bf01de50dd62b2dde8d345b.svg);
  background-size: 24px; }

header div#menu ul li a span#canal::before {
  background-image: url(/dist/files/canal.abab09741cab54be74a5216a9889ca18.svg);
  background-size: 24px; }

header div#menu ul li a span#parametres::before {
  background-image: url(/dist/files/parametres.e0711d039f7b6fa244e2a9d497172550.svg);
  background-size: 24px; }

header div#menu ul li a span#deconnexion::before {
  background-image: url(/dist/files/deconnexion.fbc9fe145fa15f51e238b1b356cbf0d7.svg);
  background-size: 24px; }

header div#menu ul li a span.canal-arrow {
  position: absolute;
  top: 20px;
  right: 12px;
  background-image: url(/dist/files/arrow-right-light.122b086a6ae42587cd6575c6c0733269.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 24px;
  height: 24px;
  background-size: 23px;
  transition: 0.5s; }

header div#menu ul li#menu-canal.is-open a span.canal-arrow {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg); }

header div#menu ul li#menu-canal .containerCanal {
  display: none; }

header div#menu ul li#menu-canal .containerCanal span.bloc-canal {
  display: none; }

header div#menu ul li#menu-canal.is-open {
  height: auto;
  background-color: #eeeeee;
  transition: 0.5s; }

header div#menu ul li#menu-canal.is-open .containerCanal {
  display: block; }

header div#menu ul li#menu-canal.is-open .containerCanal span.bloc-canal {
  display: block; }

header div#menu ul li#menu-canal.is-open span.bloc-canal {
  display: block;
  position: relative;
  font-size: 14px;
  padding: 15px 0 15px 70px; }

header div#menu ul li#menu-canal.is-open span.bloc-canal .bmd-form-group.is-filled {
  position: absolute;
  right: 10px;
  top: 9px;
  padding: 0; }

header div#menu ul li#menu-canal.is-open span.bloc-canal .bmd-form-group span.bmd-radio::before {
  background-color: #ff7900; }

header div#menu ul li#menu-canal.is-open span.bloc-canal .bmd-form-group.is-focused span.bmd-radio::after {
  border-color: #ff7900; }

header div#menu ul li#menu-canal.is-open span.bloc-canal .bmd-form-group span.bmd-radio.checked::after {
  border-color: #ff7900; }

header div#addPostMenu span#addPost-logo {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 56px;
  height: 56px;
  background-color: #ff7900;
  background-image: url(/dist/files/pen.f656c95c89aca3a6d31414b30bfb3a7e.svg);
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  background-size: 24px;
  z-index: 600; }

header div#addPostMenu ul#addPost-list {
  display: none;
  position: fixed;
  bottom: 100px;
  right: 77px;
  z-index: 600;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  text-align: right; }

header.menu-addPost-is-visible div#addPostMenu span#addPost-logo {
  background-image: url(/dist/files/croix.86333f232bd3504d57b978a60c9c1e7c.svg); }

header div#addPostMenu ul#addPost-list li {
  margin-top: 25px; }

header div#addPostMenu ul#addPost-list li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  -moz-box-shadow: 0px 1px 2px 0px #000;
  -webkit-box-shadow: 0px 2px 6px 0px #000;
  -o-box-shadow: 0px 1px 2px 0px #000;
  box-shadow: 0px 1px 2px 0px #000; }

header div#addPostMenu ul#addPost-list li a::after {
  content: "";
  position: absolute;
  right: -53px;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%; }

header div#addPostMenu ul#addPost-list li a#message::after {
  background-color: #4bb4e6;
  background-image: url(/dist/files/addPost-message.6042fb77143b28d0e349306c683d44dc.svg);
  background-size: 24px; }

header div#addPostMenu ul#addPost-list li a#aide::after {
  background-color: #50be87;
  background-image: url(/dist/files/addPost-aide.286ca9a638fec78464f7ef0bd8557dd6.svg);
  background-size: 24px; }

header div#addPostMenu ul#addPost-list li a#pratique::after {
  background-color: #ff8ad4;
  background-image: url(/dist/files/addPost-cle.17ce74096339cf722550ddae42663cd0.svg);
  background-size: 24px; }

header div#addPostMenu ul#addPost-list li a#idee::after {
  background-color: #ffb400;
  background-image: url(/dist/files/addPost-ampoule.2f860b8221ea63813552057d092d5710.svg);
  background-size: 24px; }

header div#addPostMenu ul#addPost-list li a#info::after {
  background-color: #a885d8;
  background-image: url(/dist/files/addPost-info.698c8f7ee5731b884483bfd719deb1bd.svg);
  background-size: 24px; }

header div#addPostMenu ul#addPost-list li a#sondage::after {
  background-color: #8f8f8f;
  background-image: url(/dist/files/addPost-sondage.d99abd0d0d0d05716bd43a564218fc87.svg);
  background-size: 24px; }

/* menu like */
header div#menu-like {
  position: fixed;
  left: 0;
  top: 55px;
  width: 100%;
  height: 48px;
  background-color: #000; }

header div#menu-like ul {
  width: 100%;
  height: 100%;
  display: flex; }

header div#menu-like ul li {
  width: calc(100% / 6);
  height: 100%;
  text-align: center;
  line-height: 48px;
  border-bottom: 2px solid transparent; }

header div#menu-like ul li.active {
  border-bottom: 2px solid #ff7900; }

header div#menu-like.noLike ul li {
  width: calc(100% / 5); }

header div#menu-like.noLike ul li span.nb-emotion,
header div#menu-like.noLike ul li#total-emotion {
  display: none; }

header div#menu-like ul li span {
  display: inline-block;
  vertical-align: middle; }

header div#menu-like ul li span.picto-emotion {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat; }

header div#menu-like.noLike ul li span.picto-emotion {
  width: 22px;
  height: 22px;
  background-size: 22px; }

header div#menu-like ul li span.picto-emotion#like {
  background-image: url(/dist/files/like-plein2.5698fad4484b6d08848fdbce871942d4.svg); }

header div#menu-like ul li span.picto-emotion#applaudir {
  background-image: url(/dist/files/applaudir-plein.c27d22617257567cb58c5191cb34e47a.svg); }

header div#menu-like ul li span.picto-emotion#mdr {
  background-image: url(/dist/files/mdr-plein.73723422e2f47693760540735d641e9f.svg); }

header div#menu-like ul li span.picto-emotion#etonne {
  background-image: url(/dist/files/etonnement-plein.b24a929eb7348a078d3abbad8ca0f17c.svg); }

header div#menu-like ul li span.picto-emotion#inquiet {
  background-image: url(/dist/files/enerve-plein.1091bcc52b82beb809117faf3d556856.svg); }

/** menu action (mon profil) */
div#menu-action {
  width: 100%;
  height: 48px;
  background-color: #000;
  margin-bottom: 15px; }

div#menu-action ul {
  width: 100%;
  height: 100%;
  display: flex; }

div#menu-action ul li {
  width: calc(100% / 2);
  height: 100%;
  text-align: center;
  line-height: 48px;
  border-bottom: 2px solid transparent; }

div#menu-action ul li.active {
  border-bottom: 2px solid #ff7900; }

div#menu-action ul li span {
  display: inline-block;
  vertical-align: middle; }

div#menu-action ul li span.picto {
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 8px; }

div#menu-action ul li span.picto-mesPosts {
  background-image: url(/dist/files/mesPosts.da2eb30ea2294b3e4de894a7c87c20b7.svg); }

div#menu-action ul li span.picto-aPropos {
  background-image: url(/dist/files/apropos.e60b1f37ea7bba7ffefa0be85fd7327b.svg); }

/*--------- 11 - Main ----------*/
main {
  /* height: 100vh; */
  padding-top: 55px; }

main.nav_supp {
  padding-top: 103px; }

main.selectionnerPhoto {
  padding-top: 56px; }

main.faq {
  background: #eeeeee; }

main.faq .oneElement {
  background: white; }

/*------------- 12 - Pages -------------*/
main .card {
  box-shadow: 0 3px 10px -7px rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 8px; }

/*--------- 12.0 - Page de connexion ----------*/
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.wrapperCubes {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

#cube1,
#cube2 {
  position: absolute;
  top: 0; }

#cube1 {
  left: 0;
  transform: translate3d(-50%, 0, 0); }

#cube2 {
  right: 0;
  transform: translate3d(50%, 0, 0); }

/* Tutoriel */
#tutoriel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center; }

#tutoriel #tutoPagesWrapper {
  width: 100%;
  overflow: hidden; }

#tutoriel #tutoPages {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }

#tutoriel .tutoPage {
  flex-basis: 100vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out; }

#tutoriel #cubes {
  position: absolute;
  top: 0;
  left: -50%;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  z-index: -1;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out; }

#tutoriel #cubes > * {
  /* bottom: 0; */
  /* transform: translateX(-50%); */
  width: 100vw;
  height: 100vh;
  min-width: 360px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 360px; }

#tutoriel .tutoPage img {
  margin: 0 auto;
  max-width: 300px; }

#tutoriel .tutoPage h2 {
  color: black;
  font-size: 20px;
  margin: 40px 0 20px; }

#tutoriel .tutoPage p {
  max-width: 80%;
  font-size: 18px;
  color: #8f8f8f; }

#tutoriel #tutoBottom {
  display: flex;
  justify-content: center;
  margin-top: 40px; }

#tutoriel #tutoBottom #tutoSkipButton {
  background: #eeeeee;
  color: #8f8f8f; }

#tutoriel #tutoBottom button {
  margin: 0 5px;
  display: inline-block;
  max-width: 120px; }

/*--------- 12.1 - Launcher ----------*/
div.launcher {
  background-color: #000;
  position: relative;
  width: 100%;
  height: 100vh; }

div.launcher img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100px; }

/*--------- 12.2 - Challenges ----------*/
main .card div.imageChallenge {
  width: 100%;
  height: 140px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  /*background-image: url('../images/challenges/ibiza.jpg');*/
  background-size: cover;
  background-position: center center;
  position: relative; }

/** Si challenge fini **/
/** Possibilité de l'utilisation de filter:grayscale(1); */
main .card div.imageChallenge.challengeFini::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1; }

main .card div.imageChallenge span {
  display: none; }

main .card div.imageChallenge.challengeFini span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 250px;
  height: 50px;
  background-color: transparent;
  border: 1px solid #ff7900;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  z-index: 10; }

/***/
main .card div.contenuChallenge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px; }

main .card div.contenuChallenge span {
  width: 25px;
  height: 25px;
  background-color: #ff7900;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center center; }

main .card div.contenuChallenge span#ibiza {
  background-image: url(/dist/files/palmier.9ff584a77b78e2bd90754372a75cfc3d.svg);
  background-size: 14px; }

main .card div.contenuChallenge span#etoile {
  background-image: url(/dist/files/chameau.d2e31118af552307d408403d900dc188.svg);
  background-size: 18px; }

main .card div.contenuChallenge span#pura {
  background-image: url(/dist/files/avion.b32a2afca6e5cbc41a9f75b56d13bc8d.svg);
  background-size: 15px; }

main .card div.contenuChallenge p.villeChallenge {
  display: inline-block;
  vertical-align: middle; }

main .card div.contenuChallenge p.villeChallenge::first-letter {
  text-transform: uppercase; }

/*--------- 12.3 - Notifications ----------*/
main img.pictoDesert {
  display: block;
  margin: auto;
  width: 150px;
  margin: 0 auto;
  padding-top: 50px; }

main p.notification-title {
  margin-top: 20px;
  text-align: center; }

main p.notification-message {
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto; }

main div.container_notification {
  padding: 20px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee; }

main div.container_notification .avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 20px;
  margin-top: 5px; }

main div.container_notification .notifRight {
  flex-grow: 1; }

main div.container_notification .notifRight .notifInfo {
  display: flex;
  justify-content: space-between;
  line-height: 24px; }

main div.container_notification .bloc_commentaire {
  width: calc(100% - 64px - 60px); }

main div.container_notification .bloc_action {
  margin-left: 15px;
  position: relative;
  width: 50px; }

main div.container_notification .bloc_action .date_notification {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100px;
  text-align: right; }

main div.container_notification .bloc_action span.picto-oeil {
  position: absolute;
  top: 20px;
  right: 0px;
  display: block;
  height: 24px;
  width: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/dist/files/oeil.254c2e244321c5e1036a7ba8fd462cb5.svg);
  float: right;
  margin-top: 8px; }

/*--------- 12.4 - Mon journal ----------*/
main .card.container_post {
  position: relative; }

main .card.container_post i.picto-epingle {
  display: block; }

main .card.container_post i.picto-epingle {
  position: absolute;
  top: 14px;
  right: 48px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px;
  background-image: url(/dist/files/epingle.19b572bfb8b459a271ca5eb3bc45cfac.svg); }

main .card div.bandeau_profil {
  display: flex; }

main .card div.bandeau_profil .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 13px; }

main .card div.bandeau_profil .dateEtHeure {
  display: inline-block;
  margin-left: 2px; }

main .card .attachment {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  height: 50px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin-bottom: 10px; }

main .card .attachment .attachment-icon,
main .card .attachment .attachment-download {
  height: 25px;
  width: 25px;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat; }

main .card .attachment .attachment-icon.document-pdf {
  background-image: url(/dist/files/document-pdf.e7f31a2a18b271d7b1d3eeffe1ce09ba.svg); }

main .card .attachment .attachment-icon.document-doc,
main .card .attachment .attachment-icon.document-docx {
  background-image: url(/dist/files/document-doc.d0cb3ca3706b21a04329507a00747749.svg); }

main .card .attachment .attachment-icon.document-avi {
  background-image: url(/dist/files/document-avi.01c3ba83db774e95b1461f65e5fbaf48.svg); }

main .card .attachment .attachment-icon.document-img {
  background-image: url(/dist/files/document-img.867805fb14fbe76f2128559ec590f6ff.svg); }

main .card .attachment .attachment-icon.document-ppt,
main .card .attachment .attachment-icon.document-pptx {
  background-image: url(/dist/files/document-ppt.88b19ec93466f3ce3bb2d3963cf7a242.svg); }

main .card .attachment .attachment-icon.document-xls,
main .card .attachment .attachment-icon.document-xlsx {
  background-image: url(/dist/files/document-xls.fcb6ffcf3195ec75210a1e74bea66181.svg); }

main .card .attachment .attachment-icon.document-zip {
  background-image: url(/dist/files/document-zip.779ac6cfaf28a3adc110e75596dc3ab5.svg); }

main .card .attachment .attachment-title {
  margin: 0 10px;
  font-size: 14px;
  flex: 1; }

main .card .attachment .attachment-download {
  background: url(/dist/files/picto-download.0aa52465ff6319c5851e6fa6007345a5.svg); }

main .card .image_post {
  /*width:100%;*/
  width: 100%;
  min-height: 200px;
  /*height:100%;
  height: 100vh;*/
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  /*background-image: url('../images/journal/image-journal.jpg');*/
  margin-bottom: 12px;
  cursor: zoom-in;
  border-radius: 10px; }

main .card .image_post_zoom {
  display: inline-block;
  width: 100%;
  position: relative;
  padding-top: calc(2 * 100% / 3);
  min-height: 200px; }

main .card .image_post_zoom .image_post {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  /*background-image: url('../images/journal/image-journal.jpg');*/
  margin-bottom: 12px;
  cursor: zoom-in;
  border-radius: 10px; }

main .card .info_action_post {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

main .card .info_action_post a {
  display: inline-block;
  vertical-align: middle; }

main .card .info_action_post a#nbLike {
  display: flex; }

main .card .info_action_post a#nbLike .emotionsList {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 5px; }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url('../images/icon/like-plein.svg'); */
  margin-left: -8px;
  margin-top: -1px; }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion:last-child {
  margin-left: 0px; }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion.like-stroke {
  background-image: url(/dist/files/like-plein2-stroke.58430db82229396f129b3e2fe94a75b0.svg); }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion.applaudir-stroke {
  background-image: url(/dist/files/applaudir-plein-stroke.235122e8693280a721b4763a55e5f646.svg); }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion.mdr-stroke {
  background-image: url(/dist/files/mdr-plein-stroke.7eebe81a68e388304f27ca7556c186b7.svg); }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion.etonnement-stroke {
  background-image: url(/dist/files/etonnement-plein-stroke.58435f90848ba6e2179e05d242b8209c.svg); }

main .card .info_action_post a#nbLike .emotionsList span.picto-emotion.enerve-stroke {
  background-image: url(/dist/files/enerve-plein-stroke.f0a0ed8d652e1da9f6fa3b203363c56d.svg); }

/* main .card .info_action_post a#nbLike::before{
  content:'';
  display: inline-block;
  vertical-align: middle;
  width:18px;
  height:18px;
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/icon/like-plein.svg');
  margin-right: 3px;
  margin-top: -1px;
} */
main .card .action_post {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0 5px 0;
  position: relative;
  z-index: 99; }

main .card .action_post a#like,
main .card .action_post a#commentaire {
  margin: 0 20px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 10; }

main .card .action_post a:hover,
main .card .action_post a:focus {
  color: inherit; }

main .card .action_post a#like::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/dist/files/like.210fcd0113c5df4802ab3096372ff052.svg);
  margin-top: -1px;
  margin-right: 5px; }

main .card.liked .action_post a#like::before {
  background-image: url(/dist/files/liked.d968141ee9496af8d188704f32ac037f.svg); }

main .card.liked .action_post a#like {
  color: #085ebd; }

main .card .action_post a#commentaire::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/dist/files/commentaire.95f079c2bbee14cd61852c02eeff17d0.svg);
  margin-top: 1px;
  margin-right: 5px; }

/* On screens that are 992px or less */
@media screen and (max-width: 600px) {
  main .card .containerReactions {
    padding: 10px;
    border-radius: 50vh;
    width: 100%;
    top: -55px !important;
    left: 0px !important;
    display: flex;
    opacity: 1;
    justify-content: space-between;
    cursor: pointer;
    /*background-color:pink;*/ }
  main .card .containerReactions a {
    display: inline-block;
    width: 50px;
    height: 0px;
    /* height donné en js */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    border-radius: 50%;
    z-index: 1100;
    cursor: pointer;
    margin: 2px;
    /*margin-left:3px;*/
    /*width:calc(100% / 5 - 8px);*/ } }

/* If screen size is more than 600px wide, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  main .card .containerReactions {
    padding: 10px;
    border-radius: 50vh;
    width: 100%;
    top: -55px !important;
    left: 0px !important;
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
  main .card .containerReactions a {
    display: inline-block;
    width: calc(100% / 5 - 8px);
    /*width:50px;*/
    height: 0px;
    /* height donné en js */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    border-radius: 50%;
    z-index: 1100;
    cursor: pointer;
    /*margin-left:3px;*/ } }

main .card .containerReactions a#like-plein {
  background-image: url(/dist/files/like-plein2.5698fad4484b6d08848fdbce871942d4.svg); }

main .card .containerReactions a#applaudir-plein {
  background-image: url(/dist/files/applaudir-plein.c27d22617257567cb58c5191cb34e47a.svg); }

main .card .containerReactions a#mdr-plein {
  background-image: url(/dist/files/mdr-plein.73723422e2f47693760540735d641e9f.svg); }

main .card .containerReactions a#etonnement-plein {
  background-image: url(/dist/files/etonnement-plein.b24a929eb7348a078d3abbad8ca0f17c.svg); }

main .card .containerReactions a#enerve-plein {
  background-image: url(/dist/files/enerve-plein.1091bcc52b82beb809117faf3d556856.svg); }

main .card #corner_post_action {
  position: absolute;
  top: 5px;
  right: 5px; }

main .card #corner_post_action i {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #8f8f8f; }

/*--------- 12.5 - Likes ----------*/
main img.pictoLike {
  width: 150px;
  margin: 0 auto;
  padding-top: 100px; }

main p.like-title {
  margin-top: 20px;
  text-align: center; }

main p.like-message {
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto; }

main div.container-likeCard {
  display: none;
  padding: 20px 5px 0 5px;
  align-items: center;
  justify-content: space-between; }

main #containerLikes.filter-general div.container-likeCard {
  display: flex; }

main #containerLikes.filter-like div.container-likeCard.filter-like {
  display: flex; }

main #containerLikes.filter-applaudir div.container-likeCard.filter-applaudir {
  display: flex; }

main #containerLikes.filter-mdr div.container-likeCard.filter-mdr {
  display: flex; }

main #containerLikes.filter-etonne div.container-likeCard.filter-etonne {
  display: flex; }

main #containerLikes.filter-inquiet div.container-likeCard.filter-inquiet {
  display: flex; }

main div.container-likeCard .avatar {
  display: inline-block;
  vertical-align: middle;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 10px; }

main div.container-likeCard p#name {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1; }

main div.container-likeCard p#name::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: -2px;
  margin-right: 7px; }

main div.container-likeCard.filter-like p#name::before {
  background-image: url(/dist/files/like-plein2.5698fad4484b6d08848fdbce871942d4.svg); }

main div.container-likeCard.filter-applaudir p#name::before {
  background-image: url(/dist/files/applaudir-plein.c27d22617257567cb58c5191cb34e47a.svg); }

main div.container-likeCard.filter-mdr p#name::before {
  background-image: url(/dist/files/mdr-plein.73723422e2f47693760540735d641e9f.svg); }

main div.container-likeCard.filter-etonne p#name::before {
  background-image: url(/dist/files/etonnement-plein.b24a929eb7348a078d3abbad8ca0f17c.svg); }

main div.container-likeCard.filter-inquiet p#name::before {
  background-image: url(/dist/files/enerve-plein.1091bcc52b82beb809117faf3d556856.svg); }

main div#supprimerPostModal .btn.btn-primary,
main div#signalerPostModal .btn.btn-primary {
  color: #ff7900; }

main div#supprimerPostModal div.modal-dialog,
main div#signalerPostModal div.modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%); }

/*--------- 12.6 - Creer un post ----------*/
main div.container-parameter-post {
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row; }

main div.container-parameter-post p,
main div.container-parameter-post a,
main div.container-parameter-post span {
  display: inline-block;
  vertical-align: middle; }

main div.container-parameter-post a#typeCanal::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 8px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/dist/files/chevron-down-orange.492971d5fecc4662fd039ee334ece114.svg);
  margin-left: 6px; }

main .container-input-post {
  position: relative;
  margin-bottom: 20px; }

main .container-input-post .bmd-form-group {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

main .container-input-post textarea {
  width: 100%;
  min-height: 180px;
  padding: 22px 10px 50px 65px;
  overflow: hidden;
  resize: none;
  border: 0;
  background: none; }

main .container-input-post textarea.postSondage {
  min-height: 100px; }

main .container-input-post textarea:focus {
  outline: 0; }

main .container-input-post textarea::placeholder {
  color: #d6d6d6; }

main img.post-avatar {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%; }

main .container-input-post div.spacer {
  flex: 1; }

main .container-input-post div.action-bar {
  position: absolute;
  bottom: 15px;
  left: 0px;
  width: 100%;
  padding: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px; }

main .container-input-post div.action-bar a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  line-height: 25px;
  height: 24px;
  width: 24px; }

main .container-input-post div.action-bar label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  padding: 0;
  line-height: 25px;
  height: 24px;
  width: 24px; }

main .mini-cta {
  height: auto;
  width: auto;
  display: inline-block;
  font-size: 15px;
  line-height: 100%;
  border-radius: 5px;
  height: 36px;
  padding: 0 16px;
  margin: 10px; }

main .container-input-post div.action-bar a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: -1px; }

main .container-input-post div.action-bar a#video {
  background-image: url(/dist/files/picto-video-gris.0286cad01076b5c9aeb98df0ca11c248.svg); }

main .container-input-post div.action-bar a#url {
  background-image: url(/dist/files/picto-url-gris.474bd2f4484bd970ee57d3f08ef548ec.svg); }

a#typeCanal:hover {
  color: #ff7900; }

main #modalCanal .modal-content,
main #modalBadge .modal-content {
  border-radius: 5px; }

main #modalCanal .modal-dialog,
main #modalBadge .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%); }

main #modalCanal .modal-dialog .modal-content,
main #modalBadge .modal-dialog .modal-content {
  width: 190px;
  margin: 0 auto; }

main #modalCanal .modal-dialog .modal-body,
main #modalBadge .modal-dialog .modal-body {
  padding: 15px 20px; }

main #modalCanal .modal-dialog label,
main #modalBadge .modal-dialog label {
  color: #000;
  font-weight: normal;
  padding-left: 30px; }

main #modalCanal .modal-dialog label:first-child,
main #modalBadge .modal-dialog label:first-child {
  margin-top: 8px; }

main #modalCanal .modal-dialog .modal-content span.bmd-radio,
main #modalBadge .modal-dialog .modal-content span.bmd-radio {
  margin-top: -9px; }

main #modalCanal .modal-dialog .modal-content span.bmd-radio::before,
main #modalBadge .modal-dialog .modal-content span.bmd-radio::before {
  background-color: #ff7900; }

main #modalCanal .modal-dialog .modal-content span.bmd-radio::after,
main #modalBadge .modal-dialog .modal-content span.bmd-radio::after {
  border-color: #000; }

main #modalCanal .modal-dialog .modal-content .is-focused-patch span.bmd-radio::after,
main #modalBadge .modal-dialog .modal-content .is-focused-patch span.bmd-radio::after {
  border-color: #ff7900; }

main input.choixSondage {
  width: 100%;
  height: 54px;
  padding-left: 10px;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 15px; }

main input.choixSondage:focus {
  outline: 0; }

main input.choixSondage::placeholder {
  color: #d6d6d6;
  font-size: 16px;
  line-height: 54px; }

main input.choixSondage.choixSondageGris:not(:focus):placeholder-shown {
  background: #eeeeee;
  background-image: url(/dist/files/picto-add-plein.1d34c3dce361fec59f67b89d2b3ede3a.svg);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center center; }

main input.choixSondage.choixSondageGris:not(:focus)::placeholder {
  opacity: 0; }

/* main input.choixSondage.choixSondageGris::placeholder {
  content: '';
  position: absolute;
  height: 10px;
  width: 19px;
  background-size: cover;
} */
main div.container_choix_sondage {
  margin-bottom: 10px; }

main div.container_choix_sondage div.one_choix_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px; }

main div.container_choix_sondage div.one_choix_container span.bmd-radio {
  margin: 0;
  padding: 0;
  top: -7px;
  left: 4px; }

main div.container_choix_sondage div.one_choix_container span.bmd-radio:before {
  background-color: #595959 !important; }

main div.container_choix_sondage div.one_choix_container span.bmd-radio:after {
  border-color: #595959 !important; }

main div.container_choix_sondage div.one_choix_container span.choixText {
  display: inline-block;
  /* border:1px solid #D6D6D6; */
  padding-left: 10px;
  height: 40px;
  width: calc(100% - 60px - 30px);
  line-height: 42px;
  text-align: left;
  font-size: 12px;
  position: relative;
  z-index: 10;
  background: #eeeeee;
  overflow: hidden;
  border-radius: 5px; }

main div.container_choix_sondage div.one_choix_container span.choixText .cardResultat {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* width variable défini en js */
  width: 0px;
  background-color: #b5e8f7;
  z-index: -1;
  transition-duration: 0.5s; }

main div.container_choix_sondage div.one_choix_container span.nbChoix {
  display: inline-block;
  width: 50px;
  height: 40px;
  font-size: 12px;
  line-height: 42px;
  text-align: center;
  color: #000;
  background-color: #d6d6d6;
  border-radius: 5px; }

main div#ajouterVideoModal .btn.btn-primary,
main div#ajouterUrlModal .btn.btn-primary {
  color: #ff7900; }

main div#ajouterVideoModal div.modal-dialog,
main div#ajouterUrlModal div.modal-dialog,
main div#ajouterPhotoModal div.modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%); }

main input#inputVideo,
main input#inputUrl {
  font-size: 12px;
  color: #000;
  padding-right: 30px; }

main input#inputVideo::placeholder,
main input#inputUrl::placeholder {
  font-size: 12px;
  color: #d6d6d6; }

main span.pictoInput {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px; }

.pictoWarning {
  background-image: url(/dist/files/pictoWarning.29d7cb84ebeedc1675d1a4f39ef60630.svg); }

.pictoOK {
  background-image: url(/dist/files/pictoOK.a0fb309cbef114b4fe747a58c400bcc7.svg); }

main div#ajouterPhotoModal div.container_autorisation_photo {
  width: 100%;
  display: flex;
  justify-content: space-between; }

main div#ajouterPhotoModal div.container_autorisation_photo img {
  width: 36px;
  height: 36px; }

main div#ajouterPhotoModal div.container_autorisation_photo p {
  display: block;
  width: calc(100% - 55px); }

main div#ajouterPhotoModal .modal-footer button {
  font-size: 14px;
  color: #009688;
  font-weight: 400; }

main div#ajouterPhotoModal .modal-body {
  padding: 20px 24px 0px; }

main div#ajouterPhotoModal .checkbox {
  margin-top: 20px; }

main div#ajouterPhotoModal .checkbox span.check {
  top: 2px; }

main div#ajouterPhotoModal .checkbox span#textInput {
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-top: -3px;
  margin-left: 3px; }

main div.container-image-add {
  width: 100%;
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 23px 10px;
  flex-direction: row; }

main div.container-image-add.has-photo {
  display: flex; }

main div.container-image-add .addPhotoElement {
  display: block;
  border: 1px solid #d6d6d6; }

main div.container-image-add .addPhotoElement span.iconAddPhoto {
  display: block;
  margin: 11px auto 8px;
  width: 26px;
  height: 26px;
  background-image: url(/dist/files/picto-add-photo.66e92b26bcaac9988a415bf7d88656cf.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 26px; }

main div.container-image-add .addPhotoElement span.txtAddPhoto {
  color: #d6d6d6;
  font-size: 11px;
  display: block;
  text-align: center;
  max-width: 55px;
  margin: 0 auto; }

main div.container-image-add .onePhotoElement a.deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  background-image: url(/dist/files/picto-close.874f05f0181e1ba5f03bad5fe66abff9.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 17px;
  -webkit-appearance: none;
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16); }

/*--------- 12.7 - Commentaires ----------*/
main div.container_commentaire {
  padding: 20px 20px 0 20px;
  display: flex;
  position: relative; }

main div.container_commentaire.reponse {
  padding: 15px 20px 0 40px;
  display: flex;
  position: relative; }

main div.container_commentaire .avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 20px; }

main div.container_commentaire .bloc_commentaire {
  flex-grow: 1; }

main div.container_commentaire .bloc_commentaire .commentaireRight .commentaireInfo {
  display: flex; }

main div.container_commentaire .bloc_commentaire .commentaireRight .commentaireInfo .auteurCommentaire {
  flex-grow: 1; }

main div.container_commentaire .bloc_commentaire .commentaireRight .commentaireInfo .date_commentaire {
  text-align: right; }

main div.input_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 10px 10px 10px; }

main div.input_container input {
  width: calc(100% - 63px);
  height: 54px;
  line-height: 54px;
  padding-left: 10px;
  border-radius: 8px;
  border: 0;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16); }

main div.input_container input:focus {
  outline: 0; }

main div.input_container input::placeholder {
  color: #d6d6d6;
  font-size: 14px;
  line-height: 54px; }

main div.input_container .sendMessage {
  width: 54px;
  height: 54px;
  background-color: #ff7900;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/dist/files/sendMessage.a6ffbdd0e26254276e3042cfc0aa9c71.svg);
  background-size: 24px; }

main div.container_commentaire .commentaireBottom > *,
main div.container_commentaire .commentaireBottom .dropdown-text {
  display: inline-block;
  font-size: 12px;
  color: #595959;
  font-weight: 700;
  margin-right: 20px; }

main div.container_commentaire .commentaireBottom > :last-child {
  margin-right: 0; }

main #repondreCommentaireModal .profile-reponse {
  display: flex;
  align-items: center; }

main #repondreCommentaireModal .profile-avatar img {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  margin-right: 20px; }

/*--------- 12.8 - Mon profil ----------*/
main div.container_headerProfil {
  background-color: #ff7900;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

div.container_headerProfil_photo {
  position: relative;
  padding: 4px; }

img.profil-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  z-index: 10; }

div.socle-blanc {
  position: absolute;
  left: 2px;
  top: 2px;
  background-color: #fff;
  border-radius: 50%;
  width: 84px;
  height: 84px;
  z-index: 1; }

main div.container_headerProfil .editerProfil {
  position: absolute;
  right: 0px;
  bottom: 2px;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 11; }

main div.container_headerProfil .editerProfil::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 11px;
  width: 11px;
  height: 11px;
  background-image: url(/dist/files/crayon.e4206ff11783be18f2c542f8bc8e209a.svg); }

main div.container_headerProfil div.container_headerProfil_contenu {
  flex: 1 0 100%;
  text-align: center;
  margin-top: 10px; }

main div.container_headerProfil div.container_headerProfil_contenu span.badgeMetier {
  display: inline-block;
  font-size: 10px;
  color: #000;
  background-color: #fff;
  padding: 4px 19px;
  border-radius: 10px; }

main div.container_headerProfil div.container_headerProfil_contenu p.poste {
  margin-top: 3px;
  margin-bottom: 4px; }

main div.container_apropos {
  width: 100%;
  padding: 30px 20px; }

main div.container_apropos div.oneLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap; }

main #containerGeneral-profil .nav-tabs {
  background: black;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  height: 50px; }

main #containerGeneral-profil .nav-tabs a {
  box-sizing: border-box;
  display: inline-block;
  color: white;
  line-height: 50px;
  font-size: 14px;
  height: 50px;
  border-bottom: 2px solid black; }

main #containerGeneral-profil .nav-tabs a.active {
  font-weight: bold;
  border-bottom-color: #ff7400; }

/*--------- 12.9 - Selectionner photos ----------*/
main div.container_selectionner-photos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 5px; }

main div.container_selectionner-photos .onePhotoElement {
  width: calc(100% / 3 - 3px);
  /* height ajoutée en js */
  height: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 5px; }

/*--------- 12.10 - Mes paramètres ----------*/
main#mes-parametres {
  padding: 75px 20px 20px;
  font-size: 16px; }

main#mes-parametres #info-parametres {
  font-size: 16px;
  line-height: 18px;
  color: #8f8f8f; }

main#mes-parametres #parametresNotifications > h2,
main#mes-parametres #parametresNotifications > .flex {
  margin: 15px 0; }

main#mes-parametres #parametresNotifications .icon-checkbox i {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-position: center center;
  margin-left: 15px;
  cursor: pointer;
  flex: 0 0 auto; }

main#mes-parametres #parametresNotifications .icon-checkbox input {
  display: none; }

/*--------- 12.10 - Réponses sondage ----------*/
main div.container-reponsesSondageCard {
  display: none;
  padding: 20px 5px 0 5px;
  align-items: center;
  justify-content: space-between;
  display: flex; }

main div.container-reponsesSondageCard .avatar {
  display: inline-block;
  vertical-align: middle;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-right: 10px; }

main div.container-reponsesSondageCard p#name {
  display: inline-block;
  vertical-align: middle;
  flex-grow: 1; }
