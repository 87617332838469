/********************************/
/******** TEAM UP MODIFICATION STYLE *********/
/********************************/

.bmd-label-floating.label-error {
    font-weight: bold;
    color: red;
}

button.btn.btn-primary {
    color: #ff7900;
}

button {
    font-weight: 400;
}

/*---------- 6.2 - Ma dotation ----------*/
div.containerGains .cardGain.active.first .cheminGain {
    display: none;
}

/*---------- 6.3 - Mon classement ----------*/
/* Progress bar */
.progress {
    padding-right: 15px;
}

/*---------- 6.4 - Ma position ----------*/
.badgeClassement.noir {
    background-color: #8f8f8f;
}

/*--------- 8 - Badges ----------*/

main .card div.bandeau_profil .badgeAstuce {
    margin-left: -1px;
    font-size: 12px;
}

.badgeAstuce {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 50vh;
}

.pratique {
    background-color: #ff8ad4;
}

.idee {
    background-color: #ffb400;
}

.challenge {
    background-color: #a885d8;
}

.message {
    background-color: #4bb4e6;
}

.sondage {
    background-color: #8f8f8f;
}

.aide {
    background-color: #50be87;
}

/*--------- 10 - Menus ----------*/
header div#addPostMenu ul#addPost-list li a#challenge::after {
    background-color: #a885d8;
    background-image: url(/dist/files/addPost-info.698c8f7ee5731b884483bfd719deb1bd.svg);
    background-size: 24px;
}

.ajax-load {
    padding: 10px 0px;
    width: 100%;
}
.ajax-load img {
    margin: 0 auto;
}

/*--------- 12.6 - Creer un post ----------*/

main .container-input-post #url {
    /* padding: 0 10px 0 10px; */
}

main .container-input-post-error {
    position: relative;
    padding: 5px;
    color: red;
}

main .container-input-post div.action-bar label {
    cursor: pointer;
}

main .container-input-post div.action-bar label::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: -1px;
}

main .container-input-post div.action-bar label.photo {
    background-image: url(/dist/files/picto-photo-gris.6e9ba9d10c14fc603b60d327f9298022.svg);
    background-size: cover;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

main .container-input-post div.action-bar a#video {
    background-image: url(/dist/files/picto-video-gris.0286cad01076b5c9aeb98df0ca11c248.svg);
    background-size: cover;
}

main .container-input-post div.action-bar a#url {
    background-image: url(/dist/files/picto-url-gris.474bd2f4484bd970ee57d3f08ef548ec.svg);
    background-size: cover;
}

div.container-url-add.has-url .monUrl {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

main .container-url-add span.monUrlPicto {
    background-image: url(/dist/files/picto-url-gris.474bd2f4484bd970ee57d3f08ef548ec.svg);
    background-size: cover;
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    min-width: 24px;
    min-height: 24px;
    margin-right: 5px;
    margin-top: -1px;
}

main .container-video-add span.maVideoPicto::before {
    background-image: url(/dist/files/picto-video.00b11ce0d4d11493eb00d69d4d242381.svg);
    content: "";
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center center;
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-top: -1px;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    min-height: 200px;
    margin-bottom: 10px;
    border-radius: 8px;
}

.resp-container-publier {
    position: relative;

    padding-top: 56.25%;
    min-height: 200px;
}
.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

main div.container-url-add {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 23px;
    flex-direction: row;
}
main div.container-url-add.has-url {
    display: flex;
    flex-wrap: nowrap;
}

main div.container-video-add {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 23px 10px;
    flex-direction: row;
}
main div.container-video-add.has-video {
    display: flex;
}

main div.container-image-add .onePhotoElement {
    /*width:calc(100% / 3 - 10px);*/
    width: 100%;
    /* height ajoutée en js */
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 10px;
    position: relative;
}

main div.container-url-add a.deleteUrl {
    position: absolute;
    top: -9px;
    right: -9px;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border-radius: 50%;
    background-image: url(/dist/files/picto-close.874f05f0181e1ba5f03bad5fe66abff9.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px;
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

main div.container-video-add a.deleteVideo {
    position: absolute;
    top: -9px;
    right: -9px;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border-radius: 50%;
    background-image: url(/dist/files/picto-close.874f05f0181e1ba5f03bad5fe66abff9.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 17px;
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    -o-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    z-index: 999;
}

.image_post.clic-image {
    transform: scale(1, 1);
    z-index: 100;
    padding: 5px;
}

main .container-input-post textarea {
    font-size: 16px;
}

main div.input_container_choix input {
    width: calc(100% - 20px);
    height: 54px;
    line-height: 54px;
    padding-left: 10px;
    margin: 0 10px 10px;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    font-size: 16px;
    box-shadow: none;

    -webkit-appearance: none;
}

/*--------- 12.7 - Commentaires ----------*/

main div.container_commentaire a.supprimer {
    display: inline-block;
    font-size: 12px;
    color: #595959;
    font-weight: 700;
}

main div#supprimerCommentaireModal .btn.btn-primary,
main div#signalerCommentaireModal .btn.btn-primary,
main div#repondreCommentaireModal .btn.btn-primary,
main div#modifierCommentaireModal .btn.btn-primary {
    color: #ff7900;
}

div.modal-content {
    border-radius: 8px;
}

/*------- Mon Compte Avatar ------*/
.oneAvatarElement {
    cursor: pointer;
}

.oneLine a,
.oneLine a :hover,
.oneLine a:focus {
    color: #8f8f8f;
}

.view_image_body {
    position: fixed;
    background-color: black;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    opacity: 0.8;
}

.view_image {
    /*
    height: 100%;
    width: 100%;
    
    height: 100vh;
    width: 100vw;
    top: 0px;
    left:0px;
    position: fixed;
    cursor: zoom-out;
    z-index:1000;*/
}

.view_image_img {
    position: absolute;
    margin: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    max-height: 95%;
    max-width: 95%;
    cursor: zoom-out;
}

/* FILTRE DES POSTS */
#listFiltresPost {
    display: flex;
    /* align-items: center; */
    flex-wrap: nowrap;
    height: 100%;
    overflow-x: auto;
    padding: 0 10px;
}

#listFiltresPost .badgeAstuce.all {
    background: white;
    color: black;
}

#listFiltresPost .badgeAstuce {
    transition-duration: 200ms;
    flex: 0 0 auto;
    height: 30px;
    line-height: 25px;
    font-size: 15px;
    margin-right: 10px;
    padding: 2px 10px;
}

#listFiltresPost .badgeAstuce.disable {
    background: #595959;
    color: #d6d6d6;
}

main #post-data .post-id {
    transition-duration: 1s;
}
main #post-data .post-id.hidden {
    max-height: 0px;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0px;
}
